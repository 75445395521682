import { Card, CardBody, CardFooter, CardHeader } from 'grommet'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import {
  MarketplaceCategory,
  UserMarketplaceCategoryOptions,
  WHITELABEL_PLATFORMS_HAVING_MAIN_MARKETPLACE_CATEGORY_BOX,
} from 'lib/constants'
import { assembleSubdomainAddress, routes } from 'lib/routes'
import { checkWhitelabelEnvList } from 'lib/whitelabelEnvs'

import { colors } from 'layout/themeColors/colors'
import { ProviderUser } from 'services/api/Users'

import { CardFooterContent } from './components/CardFooterContent'
import { MainMarketplaceCategoryBox } from './components/MainMarketplaceCategoryBox'
import { MarketplaceProviderCardHeader } from './components/MarketplaceProviderCardHeader/MarketplaceProviderCardHeader'
import { MarketplaceProviderCardTags } from './components/MarketplaceProviderCardTags'

interface ProviderCardProps {
  provider: ProviderUser
  sortedMarketplaceCategories?: MarketplaceCategory[]
  selectedCategoryOptions: UserMarketplaceCategoryOptions | undefined
  marketplaceLogo?: string | null
  translations:
    | {
        [key: string]: string
      }
    | undefined
}

export function MarketplaceProviderCard({
  provider,
  selectedCategoryOptions,
  sortedMarketplaceCategories,
  marketplaceLogo,
  translations,
}: ProviderCardProps) {
  const router = useRouter()
  const { t } = useTranslation()
  if (!sortedMarketplaceCategories) return null
  return (
    <Card
      style={{ justifySelf: 'center', boxShadow: `0px 0px 0px 1px ${colors.statusDisabled}` }}
      width="min(360px,100%)"
      // TODO iv PaaS demo
      {...(router.asPath === routes.paasDemo ? { height: '300px' } : { height: 'fit-content' })}>
      <CardHeader
        pad="small"
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        {/*TODO iv PaaS demo*/}
        <MarketplaceProviderCardHeader
          provider={provider}
          marketplaceLogo={marketplaceLogo ?? provider?.marketplace_logo}
          selectedCategoryOptions={selectedCategoryOptions}
          sortedMarketplaceCategories={sortedMarketplaceCategories}
          translations={translations}
        />
      </CardHeader>
      {checkWhitelabelEnvList(WHITELABEL_PLATFORMS_HAVING_MAIN_MARKETPLACE_CATEGORY_BOX) && (
        <MainMarketplaceCategoryBox
          translations={translations}
          categoryOptions={
            sortedMarketplaceCategories?.[1]
              ? selectedCategoryOptions?.[sortedMarketplaceCategories?.[1]?.id]
              : undefined
          }
        />
      )}
      <CardBody pad="medium" justify="center" align="start" gap="24px" direction="column">
        {sortedMarketplaceCategories
          ?.slice(2, sortedMarketplaceCategories.length)
          .map((category) => {
            if (selectedCategoryOptions?.[category.id]) {
              return (
                <MarketplaceProviderCardTags
                  translations={translations}
                  key={category.id}
                  categoryOptions={selectedCategoryOptions?.[category.id]}
                  label={
                    selectedCategoryOptions?.[category.id]?.length
                      ? translations?.[category.id] || category.display_name
                      : undefined
                  }
                />
              )
            }
          })}
      </CardBody>
      <CardFooter>
        <CardFooterContent
          href={assembleSubdomainAddress(provider.subdomain, routes.root)}
          label={translations?.['card-footer-label'] || t('marketplace.book-a-service')}
        />
      </CardFooter>
    </Card>
  )
}
