import { Box, Button, Text } from 'grommet'
import React from 'react'

import { CloseRateDialogIcon } from 'components/icons/common/CloseRateDialogIcon'
import { colors } from 'layout/themeColors/colors'

interface MarketplaceCategoryOptionTag {
  label: string
  background?: string
  textColor?: string
  onClear?: () => void
}

export function MarketplaceCategoryOptionTag({
  label,
  onClear,
  background = colors.highlighter,
  textColor = colors.accent5,
}: MarketplaceCategoryOptionTag) {
  return (
    <Box
      background={background}
      height="34px"
      border={{ color: colors.accent5, size: '1px' }}
      round={{ size: '8px' }}
      pad="8px 16px 8px 16px"
      align="center"
      justify="start"
      gap="small"
      direction="row">
      <Text size="14px" style={{ lineHeight: '18px' }} color={textColor}>
        {label}
      </Text>
      {onClear && (
        <Button
          plain
          icon={<CloseRateDialogIcon color={colors.lightText} height={12} width={12} />}
          onClick={onClear}
        />
      )}
    </Box>
  )
}
