import React from 'react'

import { colors } from 'layout/themeColors/colors'

const TickIcon = ({ color = colors.primaryBrand, width = '16', height = '14' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 5.80911L6.247 11.7045L14 1.70453"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default TickIcon
