import { Box, Text } from 'grommet'

import { UserMarketplaceCategoryOption } from 'lib/constants'

import { colors } from 'layout/themeColors/colors'

import { MarketplaceCategoryOptionTag } from '../../MarketplaceCategoryOptionTag'

interface MarketplaceCategoryTagsProps {
  categoryOptions?: UserMarketplaceCategoryOption[] | undefined
  label?: string
  translations:
    | {
        [key: string]: string
      }
    | undefined
}

export function MarketplaceProviderCardTags({
  categoryOptions,
  label,
  translations,
}: MarketplaceCategoryTagsProps) {
  return (
    <Box gap="small">
      {label && (
        <Text weight={600} size="14px">
          {label}
        </Text>
      )}
      <Box wrap direction="row" style={{ gap: '8px' }} width="100%">
        {categoryOptions?.map((option) => (
          <MarketplaceCategoryOptionTag
            key={option.id}
            label={translations?.[option.id] ?? option.display_name}
            background={colors.light2}
            textColor={colors.dark}
          />
        ))}
      </Box>
    </Box>
  )
}
