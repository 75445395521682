import { Box, Text } from 'grommet'

import { UserMarketplaceCategoryOption } from 'lib/constants'

import { colors } from 'layout/themeColors/colors'

interface MainMarketplaceCategoryBoxProps {
  categoryOptions?: UserMarketplaceCategoryOption[]
  translations:
    | {
        [key: string]: string
      }
    | undefined
}

export function MainMarketplaceCategoryBox({
  categoryOptions,
  translations,
}: MainMarketplaceCategoryBoxProps) {
  return (
    <Box
      background={colors.highlighter}
      width="100%"
      justify="center"
      align="center"
      pad={{ bottom: 'small', top: 'small', left: '16px', right: '16px' }}
      style={{ minHeight: '42px' }}>
      <Text size="14px" weight={400} color={colors.accent5} textAlign="center">
        {categoryOptions
          ?.map(
            (categoryOption) => translations?.[categoryOption.id] || categoryOption.display_name,
          )
          .join(', ')}
      </Text>
    </Box>
  )
}
