import { Box, Image, Text } from 'grommet'
import React, { useState } from 'react'

import { COUNTRY_EMOJIS } from 'lib/constants'

import { ProviderUser } from 'services/api/Users'

interface ChesscomMarketplaceProviderCardHeaderProps {
  subtitle?: string | React.ReactElement
  marketplaceLogo?: string | null
  provider: ProviderUser
}

export function ChesscomMarketplaceProviderCardHeader({
  subtitle,
  marketplaceLogo,
  provider,
}: ChesscomMarketplaceProviderCardHeaderProps) {
  const [error, setError] = useState(false)

  return (
    <Box
      direction="row"
      align="center"
      gap="medium"
      justify="start"
      pad={{ left: 'small', right: 'small' }}>
      {marketplaceLogo && (
        <Image
          style={{
            maxHeight: '80px',
            borderRadius: '12px',
          }}
          fit="contain"
          src={!error ? marketplaceLogo : undefined}
          onError={error ? () => setError(true) : undefined}
        />
      )}
      <Box direction="column">
        <Box direction="row" align="center" gap="4px">
          <Text size="16px" weight={600}>
            {provider.name}
          </Text>
          {provider.iso_country_code && COUNTRY_EMOJIS?.[provider.iso_country_code]?.emoji}
        </Box>
        {subtitle}
      </Box>
    </Box>
  )
}
