import { Box, Heading, Image, Text } from 'grommet'
import { useState } from 'react'

import { colors } from 'layout/themeColors/colors'
import { ProviderUser } from 'services/api/Users'

interface ContabiMarketplaceProviderCardHeaderProps {
  subtitle?: string | null
  marketplaceLogo?: string | null
  provider: ProviderUser
}

export function ContabiMarketplaceProviderCardHeader({
  subtitle,
  marketplaceLogo,
  provider,
}: ContabiMarketplaceProviderCardHeaderProps) {
  const [error, setError] = useState(false)

  return (
    <Box
      direction="row"
      align="start"
      gap="medium"
      justify="start"
      pad={{ left: 'small', right: 'small' }}>
      {marketplaceLogo && (
        <Image
          style={{ maxHeight: '80px', borderRadius: '12px' }}
          fit="contain"
          src={!error ? marketplaceLogo : undefined}
          onError={error ? () => setError(true) : undefined}
        />
      )}
      <Box direction="column">
        <Heading size="16px">{provider.name}</Heading>
        <Text size="14px" color={colors.lightText}>
          {subtitle}
        </Text>
      </Box>
    </Box>
  )
}
