import { Box, Text } from 'grommet'
import { useRouter } from 'next/router'

import { MarketplaceCategory, UserMarketplaceCategoryOptions } from 'lib/constants'
import { routes } from 'lib/routes'
import { checkWhitelabelEnv, WHITELABEL_ENVS } from 'lib/whitelabelEnvs'

import { colors } from 'layout/themeColors/colors'
import { ProviderUser } from 'services/api/Users'

import { ChesscomMarketplaceProviderCardHeader } from './ChesscomMarketplaceProviderCardHeader'
import { ContabiMarketplaceProviderCardHeader } from './ContabiMarketplaceProviderCardHeader'
import { DefaultMarketplaceProviderCardHeader } from './DefaultMarketplaceProviderCardHeader'

interface MarketplaceProviderCardHeaderProps {
  marketplaceLogo?: string | null
  provider: ProviderUser
  sortedMarketplaceCategories?: MarketplaceCategory[]
  selectedCategoryOptions: UserMarketplaceCategoryOptions | undefined
  translations:
    | {
        [key: string]: string
      }
    | undefined
}

export function MarketplaceProviderCardHeader({
  marketplaceLogo,
  provider,
  selectedCategoryOptions,
  sortedMarketplaceCategories,
  translations,
}: MarketplaceProviderCardHeaderProps) {
  if (!sortedMarketplaceCategories) return null
  const router = useRouter()
  const isPaasDemo = router.asPath === routes.paasDemo || router.asPath === routes.paasDemoIPad

  if (checkWhitelabelEnv(WHITELABEL_ENVS.CONTABI)) {
    return (
      <ContabiMarketplaceProviderCardHeader
        provider={provider}
        marketplaceLogo={marketplaceLogo}
        subtitle={
          selectedCategoryOptions?.[sortedMarketplaceCategories?.[0]?.id]?.[0]?.id
            ? translations?.[
                selectedCategoryOptions?.[sortedMarketplaceCategories?.[0]?.id]?.[0]?.id
              ]
            : undefined
        }
      />
    )
  }
  if (checkWhitelabelEnv(WHITELABEL_ENVS.CHESSCOM) || isPaasDemo) {
    return (
      <ChesscomMarketplaceProviderCardHeader
        provider={provider}
        marketplaceLogo={marketplaceLogo}
        subtitle={
          <Box direction="row" gap="small" align="center">
            {selectedCategoryOptions?.[sortedMarketplaceCategories?.[1]?.id]?.[0]?.id && (
              <Text
                style={{
                  backgroundColor: colors.statusError,
                  height: 'fit-content',
                  alignSelf: 'center',
                  padding: '2px',
                }}
                weight={700}
                color={colors.white}>
                {translations?.[
                  selectedCategoryOptions?.[sortedMarketplaceCategories?.[1]?.id]?.[0]?.id
                ] ||
                  selectedCategoryOptions?.[sortedMarketplaceCategories?.[1]?.id]?.[0]
                    ?.display_name}
              </Text>
            )}

            <Text size="14px" color={colors.lightText}>
              {selectedCategoryOptions?.[sortedMarketplaceCategories?.[0]?.id]?.[0]?.id
                ? '📍' +
                  (translations?.[
                    selectedCategoryOptions?.[sortedMarketplaceCategories?.[0]?.id]?.[0]?.id
                  ] ||
                    selectedCategoryOptions?.[sortedMarketplaceCategories?.[0]?.id]?.[0]
                      ?.display_name)
                : ''}
            </Text>
          </Box>
        }
      />
    )
  }

  return (
    <DefaultMarketplaceProviderCardHeader
      provider={provider}
      marketplaceLogo={marketplaceLogo}
      subtitle={
        selectedCategoryOptions?.[sortedMarketplaceCategories?.[0]?.id]?.[0]?.id
          ? translations?.[
              selectedCategoryOptions?.[sortedMarketplaceCategories?.[0]?.id]?.[0]?.id
            ] || selectedCategoryOptions?.[sortedMarketplaceCategories?.[0]?.id]?.[0]?.display_name
          : undefined
      }
    />
  )
}
