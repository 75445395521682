import { Anchor, Box, ResponsiveContext, Text } from 'grommet'
import { useContext } from 'react'

import { colors } from 'layout/themeColors/colors'

interface BookAServiceButtonProps {
  href: string
  label: string
}

export function CardFooterContent({ href, label }: BookAServiceButtonProps) {
  const deviceSize = useContext(ResponsiveContext)
  const isMobile = deviceSize === 'small'

  return (
    <Anchor target="_blank" href={href} style={{ textDecoration: 'none', width: '100%' }}>
      <Box
        background={colors.accent5}
        pad={{
          left: '16px',
          right: '16px',
          top: isMobile ? '8px' : '12px',
          bottom: isMobile ? '8px' : '12px',
        }}
        height="54px"
        align="center"
        width="100%"
        direction="row"
        justify="around">
        <Text size="16px" weight={600} textAlign="center" color={colors.white}>
          {label}
        </Text>
      </Box>
    </Anchor>
  )
}
